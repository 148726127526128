/* Styles go here */
.id {
  border-radius: 50%;
}

.network {
  width: 125px;
  height: 43px;
  color: #FFFFFF;
  background-color: #303030;
  border: 1mm ridge #55FF55;
}

.balance {
  width: 125px;
  height: 43px;
  color: #FFFFFF;
  background-color: #505050;
  border: 1mm ridge #55FF55;
}

.content small {
  color: #CCC;
}

.account {
  width: 150px;
  height: 43px;
  color: #55FF55;
  background-color: #707070;
  border: 1mm ridge #55FF55;
}

.bgDark7 {
  background-color: #1D1D1D;
}

.navBorderBottom{
  border-bottom: 1mm ridge rgba(85, 255, 85, .8)
}

.loading{
  height: 1440px;
  background-color: #1D1D1D;
}

p {
  margin: 5px
}